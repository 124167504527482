<template>
  <GMapMap
      :center="center"
      :zoom="13"
      map-type-id="terrain"
      style="width: 500px; min-height: 63vh"
  >
    <GMapCluster>
      <GMapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="true"
          @click="center=m.position"
      />
    </GMapCluster>
  </GMapMap>
</template>
<script>
export default {
  name: 'App',
  data () {
    return {
      center: { lat: -6.174430777160135, lng: 106.65497704506025 },
      markers: [
        {
          position: {
            lat: -6.174430777160135, lng: 106.65497704506025
          }
        } // Along list of clusters
      ]
    }
  }
}
</script>
<style scoped>
    .vue-map-container{
        height: 100%;
    }
</style>
