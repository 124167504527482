<template>
  <div class="contact">
    <div class="contact-header">
      <div class="wrapper-header-left">
        <transition name="slide-fade" >
          <h1 v-if="titleShow"><span v-if="$store.state.lang == 'IN'">Kontak Kami</span><span v-if="$store.state.lang == 'EN'">Our Contact</span></h1>
        </transition>
        <transition name="slide-fade" >
          <h3 v-if="taglineShow" >
            <span v-if="$store.state.lang == 'IN'">Hubungi kontak kami dibawah ini untuk mendapatkan info lebih lengkap.</span>
            <span v-if="$store.state.lang == 'EN'">Contact us below for more information.</span>
          </h3>
        </transition>
      </div>
    </div>
    <div class="wrepper-content-contact">
        <transition name="slide-fade" >
          <div class="content-left" v-show="sItemA" >
              <h2> Gigihpack </h2>
            <p><it-icon name="place" color="#644627" /> JL. Benteng Betawi No. 29 Tanah Tinggi, Batuceper, Tangerang, Banten. 15119</p>
            <h3> Telp / Whatsapp </h3>
            <p>
              <it-icon name="phone" color="#644627" /> +62 813 8306 5737 (Risa) <br/>
              <it-icon name="phone" color="#644627" /> +62 859 5509 1222 (Putra)<br/>
              <it-icon name="phone" color="#644627" /> +62 857 7745 4799 (Ruli)
            </p>
            <h3> Email</h3>
            <p><it-icon name="mail" color="#644627" /> marketing@gigihpack.com</p>
            <h3> Social Media </h3>
            <p>
              <a href="https://web.facebook.com/profile.php?id=100076291816611" target="_blank"> <span class="iconify" data-icon="mdi:facebook"></span> gigihpack</a><br/>
              <a href="https://www.instagram.com/hallogigihpack" target="_blank"> <span class="iconify" data-icon="mdi:instagram"></span> hallogigihpack</a>
            </p>
          </div>
        </transition>
        <transition enter-active-class="animated flipInX" >
          <div v-show="mapShow" class="content-right" >
            <item-maps />
          </div>
        </transition>
      <Waypoint @change="showItemA"></Waypoint>
    </div>
  </div>
</template>

<script>
import { Waypoint } from 'vue-waypoint'
import ItemMaps from '../components/item/ItemMaps.vue'

export default {
  name: 'Contact',
  components: {
    Waypoint,
    ItemMaps
  },
  data () {
    return {
      sItemA: false,
      titleShow: false,
      taglineShow: false,
      mapShow: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.titleShow = true
    }, 100)
    setTimeout(() => {
      this.taglineShow = true
    }, 300)
    setTimeout(() => {
      this.imgShow = true
    }, 500)
  },
  methods: {
    showItemA (waypointState) {
      if ((waypointState.going === 'IN' || waypointState.going === 'OUT')) {
        setTimeout(() => {
          this.sItemA = true
          this.mapShow = true
        }, 300)
      }
    }
  }
}
</script>
<style lang="scss">
  .contact{
    position: relative;
    min-height: 90vh;
    padding: 2.5rem;
    text-align: left;
    background: #fff;
    border-radius: 1rem 1rem 0rem 0rem;
    .contact-header{
      background-image: url("../assets/image/IMAGE4.jpg");
      box-shadow: inset 0 0 0 2000px rgb(100 70 39 / 45%);
      position: relative;
      background-color: #fff;
      overflow: hidden;
      border-radius: 0.8rem;
      margin: -2.5rem;
      background-attachment: fixed;
      background-position-x: 21rem;
      background-repeat: no-repeat;
      background-position-y: 3rem;
      background-size: cover;
      background-size: 100%;
      min-height: 90vh;
      .wrapper-header-left{
        display: inline-grid;
        width: 100%;
      }
      h1{
        font-size: 3rem;
        font-family: LondonTwo;
        color: #fff;
        text-shadow: -1px -1px 0px #644627;
        text-align: left;
        float: left;
        margin-left: 12%;
        margin-top: 35vh;
        margin-bottom: 0rem;
        span {
          border-radius: .6rem;
          display: inline-flex;
          height: 1.5rem;
          line-height: 0px;
        }
      }
      h3{
        float: left;
        color: #fff;
        text-align: left;
        width: 92%;
        margin-left: 12%;
        margin-bottom: 15rem;
        text-shadow: 0px 1px 2px #644627;
      }
    }
    h2, h3, h4{
      color: #644627;
      text-shadow: 0px 1px 2px #fff;
      margin-bottom: .3rem;
      span{
        float: left;
      }
    }
    p {
      line-height: 1.5rem;
      .it-icon{
        vertical-align: middle;
      }
    }
    .it-btn{
      float: left;
      margin-right: 0.5rem;
      margin-top: 0.5rem;
    }
    .iconify{
        vertical-align: middle;
    }
    .wrepper-content-contact{
      position: relative;
      width: 100%;
      margin-top: 6rem;
      overflow: hidden;
      .content-left{
        display: inline-block;
      }
      .content-right{
        display: inline-block;
        float: right;
        width: 50%;
        height: 450px;
        background-color: rgba(135, 92, 45,0.8);
        border-radius: 1rem;
        text-align: center;
        vertical-align: middle;
      }
    }
  }
  @media only screen and (max-width: 650px) {
    .contact{
      .contact-header{
        background-position-x: 2rem;
        background-position-y: 15rem;
        background-size: cover;
        background-size: 200%;
        min-height: 90vh;
        h2{
          font-size: 2rem;
          margin-left: 27%;
        }
        h3{
          font-size: 1rem;
          width: 85%;
          margin-left: 9%;
          text-align: center;
        }
      }
      .wrepper-content-contact{
        margin: 0rem;
        margin-top: 4rem;
        .content-right{
          margin-right: 0rem;
          width: 100%;
          margin-top: 2rem;
        }
      }
    }
  }
</style>
